import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowFilterModalMobile } from '../../redux/utils/actions';
import { utilsSelector } from '../../redux/utils/selectors';
import Modal from '../Modal';
import { Text } from '../Text';
import { ContentTypesFilter } from '../ContentTypeFilter';
import { DateFilter } from '../DateFilter';
import { KeywordsFilter } from '../KeywordsFilter';
import { ProvidersFilter } from '../ProvidersFilter';
import { SentimentFilter } from '../SentimentFilter';
import NlpModeFilter from '../NLpModeFilter';

export const FilterModalMobile: FC = () => {
  const { showFilterModalMobile } = useSelector(utilsSelector);
  const dispatch = useDispatch();

  return showFilterModalMobile ? (
    <Modal
      title={'Filtri'}
      showCloseButton={true}
      onClickCloseButton={() => dispatch(setShowFilterModalMobile(false))}
    >
      <div className="">
        <div className="flex flex-col space-y-2">
          <div className="flex border-b pb-2 flex-col">
            <Text text="Scegli keyword" className="font-semibold italic pb-2" />
            <KeywordsFilter isDisabled={false} />
          </div>
          <div className="flex border-b py-2 flex-col">
            <NlpModeFilter isDisabled={false} />
          </div>
          <div className="flex border-b py-2 flex-col">
            <Text text="Scegli periodo" className="font-semibold italic pb-2" />
            <DateFilter isDisabled={false} />
          </div>
          <div className="flex border-b py-2 flex-col">
            <Text text="Scegli agenzia" className="font-semibold italic pb-2" />
            <ProvidersFilter isDisabled={false} />
          </div>
          <div className="flex border-b py-2 flex-col">
            <Text
              text="Scegli tipo di media"
              className="font-semibold italic pb-2"
            />
            <ContentTypesFilter isDisabled={false} />
          </div>
          <div className="flex border-b py-2 flex-col">
            <Text
              text="Scegli sentiment"
              className="font-semibold italic pb-2"
            />
            <SentimentFilter isDisabled={false} />
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};
