/* eslint-disable no-plusplus */
import { WindowType } from '@nwa/graphql';
import chroma from 'chroma-js';
import DockLayout, { BoxData, PanelData } from 'rc-dock';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { DELETE_BOOKMARKS } from '../../graphql/news/mutation';
import { bookmarksList } from '../../redux/filterChoices/costant';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import {
  removeBackupNewsFilter,
  restoreBackupNewsFilter,
  setNewsFilter,
} from '../../redux/newsFilter/actions';
import { forceRefresh } from '../../redux/newsSelection/actions';
import { setDockSizes, setIsAWindowMaximized } from '../../redux/utils/actions';
import { utilsSelector } from '../../redux/utils/selectors';
import {
  removeBackupWorkingAreaFilter,
  removeDateRange,
  restoreBackupWorkingAreaFilter,
  setSelectedWindow,
  setWorkingAreaFilter,
} from '../../redux/workingAreas/actions';
import {
  hasFullTextFilter,
  numberOfFilter,
  workingAreasSelector,
} from '../../redux/workingAreas/selectors';
import {
  Add,
  Back,
  Eye,
  Fullscreen,
  FullscreenExit,
  GrayBookmark,
} from '../../svg';
import { getDateAsString } from '../../utils/dateUtils';
import { environmentMapper } from '../../utils/stringUtils';
import { useMutationHook } from '../hooks/useMutationHook';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';

interface TitleTabProps {
  id: string;
  dockLayout?: DockLayout;
}

export const TitleTab: FC<TitleTabProps> = ({ id, dockLayout }) => {
  const dispatch = useDispatch();
  const {
    draftWorkingArea,
    dateRange: dateRangeWorkingArea,
    selectedWindow,
    windowCounts,
  } = useSelector(workingAreasSelector);

  const numberFilter = useSelector((state: any) => numberOfFilter(state, id));
  const hasTextFilter = useSelector((state: any) =>
    hasFullTextFilter(state, id)
  );
  const { keywords, contentTypes } = useSelector(filterChoicesSelector);

  const searchValue = draftWorkingArea?.windows.filter((w) => w.id === id)[0]
    ?.value;

  const selected = useMemo(() => selectedWindow === id, [selectedWindow, id]);

  const [removeAllBookmarksMutation] = useMutationHook({
    queryGql: DELETE_BOOKMARKS,
  });

  const removeAllBookmarks = () => {
    removeAllBookmarksMutation({
      variables: { color: searchValue?.color },
    }).then(() => dispatch(forceRefresh()));
  };

  const title = useMemo(() => {
    let Icon = <Add className="fill-black" />;
    let TextToShow = <Text text="Nuova finestra senza titolo" />;
    if (
      searchValue?.search &&
      (searchValue.search.fullSearchText || searchValue.search.environment)
    ) {
      const env = environmentMapper(searchValue.search.environment!);
      Icon = env!.Icon;
      TextToShow = searchValue.search.fullSearchText ? (
        <Text text={searchValue.search.fullSearchText} skipTranslation={true} />
      ) : (
        <Text text={env!.value} />
      );
    }
    if (searchValue?.type && searchValue.type === WindowType.BOOKMARK) {
      Icon = bookmarksList.find(
        (bookmark) => bookmark.value === searchValue?.color
      )!.Icon;
      TextToShow = <Text text="Contrassegnati" />;
    }
    if (searchValue?.type && searchValue.type === WindowType.PREVIEW) {
      Icon = <Eye />;
      TextToShow = <Text text="Finestra di visualizzazione" />;
    }

    return (
      <div className="flex items-center min-w-0">
        <SvgIcon svg={Icon} className="h-5 w-5" />
        <div className="ml-2 overflow-ellipsis overflow-hidden whitespace-nowrap">
          {TextToShow}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      dispatch(removeBackupNewsFilter(id));
      dispatch(removeBackupWorkingAreaFilter(id));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ref = useRef<any | null>(null);
  const { dockSizes } = useSelector(utilsSelector);
  const [maximized, setMaximized] = useState(false);

  const maximizeChildren: any = (
    children: (BoxData | PanelData)[],
    newDockSizes: {
      [key: string]: number;
    }
  ) => {
    return [
      children.map((child) => {
        if ((child as PanelData).activeId) {
          // è una "tab"
          if ((child as PanelData).activeId === id) {
            return child;
          } else {
            newDockSizes[child.id || ''] = child.size || 0;
            return { ...child, size: 0 };
          }
        } else {
          // è un "sotto-layout"
          newDockSizes[child.id || ''] = child.size || 0;
          const [children, newDockSizesTmp] = maximizeChildren(
            (child as BoxData).children,
            newDockSizes
          );
          newDockSizes = newDockSizesTmp;
          const size = children.some(
            (child: BoxData | PanelData) => child.size && child.size > 0
          )
            ? child.size
            : 0;
          return {
            ...child,
            children,
            size,
          };
        }
      }),
      newDockSizes,
    ];
  };

  const demaximizeChildren: any = (children: (BoxData | PanelData)[]) => {
    return children.map((child) => {
      if ((child as PanelData).activeId) {
        // è una "tab"
        if ((child as PanelData).activeId === id) {
          return child;
        } else {
          return {
            ...child,
            size: dockSizes[child.id || ''] || 100,
          };
        }
      } else {
        // è un "sotto-layout"
        const children = demaximizeChildren((child as BoxData).children);
        return {
          ...child,
          children,
          size: dockSizes[child.id || ''] || 100,
        };
      }
    });
  };

  const maximize = () => {
    if (ref && dockLayout) {
      let layoutData = dockLayout.getLayout();
      //console.log('layoutData-prima', layoutData);
      if (!maximized) {
        const [children, newDockSizes] = maximizeChildren(
          layoutData.dockbox.children,
          {}
        );
        layoutData = {
          ...layoutData,
          dockbox: {
            ...layoutData.dockbox,
            children,
          },
        };
        setMaximized(true);
        dispatch(setDockSizes(newDockSizes));
        dispatch(setIsAWindowMaximized(true));
      } else {
        layoutData = {
          ...layoutData,
          dockbox: {
            ...layoutData.dockbox,
            children: demaximizeChildren(layoutData.dockbox.children),
          },
        };
        dispatch(setDockSizes({}));
        setMaximized(false);
        dispatch(setIsAWindowMaximized(false));
      }
      //console.log('layoutData-dopo', layoutData);
      dockLayout.changeLayout(layoutData, id, 'update');
    }
  };

  return (
    <div className="flex flex-row align-middle">
      <div
        ref={ref}
        data-tip={''}
        data-for={'tooltip-bar' + id}
        onClick={() => {
          if (!selected) {
            const newWindow = draftWorkingArea?.windows.filter(
              (w) => w.id === id
            )[0];
            dispatch(
              setNewsFilter({
                dateRange: dateRangeWorkingArea.filter((dr) => dr.id === id)[0]
                  ?.date,
                expressions: newWindow?.value.search?.keywords,
                providers: newWindow?.value.search?.providers || [],
                sentiments: newWindow?.value.search?.sentiments || [],
                contentTypes: newWindow?.value.search?.contentTypes,
                fullSearchText:
                  newWindow?.value.search?.fullSearchText || undefined,
                keywords:
                  newWindow?.value.search?.keywords?.map(
                    (keywordExpression: string) =>
                      keywords.find((k) => keywordExpression === k.expression)!
                  ) || [],
                fullSearchTextError: false,
                isNlpMode: false,
              })
            );
            dispatch(setSelectedWindow(id));
          }
        }}
        className="flex w-full align-middle min-w-0 justify-between items-center overflow-hidden"
      >
        <div className="flex">
          {searchValue?.type &&
            searchValue.type === WindowType.SEARCH &&
            selected &&
            (numberFilter > 0 || hasTextFilter) && (
              <span
                className="flex p-1 items-center cursor-pointer mr-2"
                style={{
                  fill: '#5B84EF',
                  color: '#5B84EF',
                  fontSize: '0.625rem',
                  lineHeight: '0.75rem',
                }}
                onClick={() => {
                  dispatch(
                    setNewsFilter({
                      keywords: [],
                      providers: [],
                      sentiments: [],
                      contentTypes: [],
                      dateRange: undefined,
                      expressions: [],
                      fullSearchText: '',
                      fullSearchTextError: false,
                      isNlpMode: false,
                    })
                  );
                  dispatch(
                    setWorkingAreaFilter({
                      contentTypes: [],
                      fullSearchText: '',
                      keywords: [],
                      providers: [],
                      sentiments: [],
                      fullSearchTextError: false,
                      isNlpMode: false,
                    })
                  );
                  dispatch(removeDateRange());
                }}
              >
                <SvgIcon svg={<Back />} />
                &nbsp;
                <Text
                  text="Rimuovi filtri"
                  style={{
                    fontSize: '0.75rem',
                    lineHeight: '1rem',
                  }}
                />
              </span>
            )}
          {selected &&
            searchValue?.type &&
            searchValue.type === WindowType.BOOKMARK && (
              <span
                className="flex p-1 items-center cursor-pointer mr-2"
                style={{
                  fill: '#5B84EF',
                  color: '#5B84EF',
                  fontSize: '0.625rem',
                  lineHeight: '0.75rem',
                }}
                onClick={() => {
                  dispatch(restoreBackupNewsFilter(id));
                  dispatch(restoreBackupWorkingAreaFilter(id));
                }}
              >
                <SvgIcon svg={<Back />} />
                &nbsp;
                <Text
                  text="Indietro"
                  style={{
                    fontSize: '0.75rem',
                    lineHeight: '1rem',
                  }}
                />
              </span>
            )}
          {title}
          {searchValue?.type && searchValue.type === WindowType.SEARCH && (
            <div className="flex">
              {numberFilter > 0 && (
                <span
                  className="flex ml-2 p-1 items-center overflow-ellipsis overflow-hidden whitespace-nowrap"
                  style={{
                    fontSize: '0.625rem',
                    lineHeight: '0.75rem',
                  }}
                >
                  {searchValue?.search?.keywords?.map((keywordExp) => {
                    const keyword = keywords.find(
                      (k) => k.expression === keywordExp
                    ) || { id: '-1', name: 'Not Found', color: 'red' };
                    return keyword.id !== '-1' ? (
                      <div
                        style={{
                          backgroundColor: chroma(keyword.color)
                            .alpha(0.1)
                            .css(),
                          color: keyword.color,
                        }}
                        className="flex p-1 rounded space-x-1"
                        key={'key' + keyword.id + id}
                      >
                        <Text
                          text={keyword.name}
                          skipTranslation={true}
                          style={{
                            fontSize: '0.625rem',
                            lineHeight: '0.75rem',
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    );
                  })}
                  {searchValue?.search?.providers?.map((provider) => (
                    <div
                      style={{
                        background: 'rgba(121, 114, 114, 0.1)',
                        color: '#1F1F1F',
                      }}
                      className="flex p-1 rounded ml-1"
                      key={provider + id}
                    >
                      <Text
                        text={provider}
                        skipTranslation={true}
                        style={{
                          fontSize: '0.625rem',
                          lineHeight: '0.75rem',
                        }}
                      />
                    </div>
                  ))}
                  {searchValue?.search?.sentiments?.map((sentiment) => (
                    <div
                      style={{
                        background: 'rgba(121, 114, 114, 0.1)',
                        color: '#1F1F1F',
                      }}
                      className="flex p-1 rounded ml-1"
                      key={sentiment + id}
                    >
                      <Text
                        text={sentiment}
                        skipTranslation={true}
                        style={{
                          fontSize: '0.625rem',
                          lineHeight: '0.75rem',
                        }}
                      />
                    </div>
                  ))}
                  {dateRangeWorkingArea
                    .filter((dateRange) => dateRange.id === id)
                    .map((dateRange) => (
                      <span
                        className="flex p-1 rounded ml-1"
                        key={'titlebardaterage' + dateRange.id}
                      >
                        <Text
                          text={`${getDateAsString(
                            dateRange.date.from as unknown as number
                          )}
                          - 
                          ${getDateAsString(
                            dateRange.date.to as unknown as number
                          )}`}
                          skipTranslation={true}
                          style={{
                            fontSize: '0.625rem',
                            lineHeight: '0.75rem',
                          }}
                        />
                      </span>
                    ))}
                  {searchValue?.search?.contentTypes?.map((contentType) => {
                    const contentTypeObj = contentTypes.find(
                      (c) => c.value === contentType
                    ) || { value: '-1', label: 'Not Found', Icon: null };
                    return contentTypeObj.value !== '-1' ? (
                      <div
                        className="flex p-1 rounded ml-1"
                        style={{
                          fontSize: '0.625rem',
                          lineHeight: '0.75rem',
                        }}
                        key={contentType + id}
                      >
                        <SvgIcon
                          svg={contentTypeObj.Icon}
                          className="w-4 h-4 pt-0.5"
                        />
                        <div>
                          <Text
                            text={contentTypeObj.label}
                            style={{
                              color: '#838383',
                              fontSize: '0.625rem',
                              lineHeight: '0.75rem',
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    );
                  })}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row align-middle items-center">
          {searchValue?.type && searchValue.type === WindowType.SEARCH && (
            <div
              className="flex"
              style={{
                fontSize: '0.625rem',
                lineHeight: '0.75rem',
              }}
            >
              (
              <Text
                text={`${
                  typeof windowCounts[id] != 'undefined'
                    ? windowCounts[id]
                    : '-'
                }`}
                skipTranslation={true}
                style={{
                  fontSize: '0.625rem',
                  lineHeight: '0.75rem',
                }}
              />
              &nbsp;
              <Text
                text="risultati"
                style={{
                  fontSize: '0.625rem',
                  lineHeight: '0.75rem',
                }}
              />
              )
            </div>
          )}
          {selected &&
            searchValue?.type &&
            searchValue.type === WindowType.BOOKMARK && (
              <div
                className="flex justify-between items-center p-1 bg-white cursor-pointer min-w-0"
                style={{
                  border: '1px solid #5B84EF',
                  borderRadius: '5px',
                  boxSizing: 'border-box',
                  color: '#5B84EF',
                  fontSize: '0.625rem',
                  lineHeight: '0.75rem',
                }}
                onClick={removeAllBookmarks}
              >
                <Text
                  text="Rimuovi contrassegnati"
                  className="overflow-ellipsis overflow-hidden whitespace-nowrap"
                  style={{
                    fontSize: '0.625rem',
                    lineHeight: '0.75rem',
                  }}
                />
                <SvgIcon svg={<GrayBookmark />} className="w-4 h-4" />
              </div>
            )}
        </div>
      </div>
      {selected && (
        <SvgIcon
          className="ml-1 pt-1"
          svg={
            maximized ? (
              <FullscreenExit className="h-5 w-5" />
            ) : (
              <Fullscreen className="h-5 w-5" />
            )
          }
          pointer
          onClick={maximize}
        />
      )}
    </div>
  );
};
