import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components, InputActionMeta, StylesConfig } from 'react-select';
import Select from 'react-select';
import { setFullSearchTextFilter } from '../../redux/newsFilter/actions';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { Search } from '../../svg';
import { FilterBoxChildrenProps } from '../FilterBox';
import { SvgIcon } from '../SvgIcon';
import { setWorkingAreaFilter } from '../../redux/workingAreas/actions';
import { setShowNewsSearchModal } from '../../redux/utils/actions';
import { utilsSelector } from '../../redux/utils/selectors';
import lodash from 'lodash';
import { handleTokenAsPredicate, mapBoonExprToType } from '../../utils/boon';

export const NewsSearchWithoutSuggestions: FC<FilterBoxChildrenProps> = ({
  isDisabled,
}) => {
  const { isMobile } = useSelector(utilsSelector);
  const [input, setInput] = useState('');
  const [inputSave, setSave] = useState('');
  const dispatch = useDispatch();

  const newsFilter = useSelector(newsFilterSelector);

  const isNlp = useMemo(() => {
    // TEMPORARY FIX: Always return false
    // TODO: Remove this override and restore original logic below -> return false
    return newsFilter.isNlpMode;

    /* Original logic preserved for reference
  return !newsFilter.dateRange;
  */
  }, [newsFilter.isNlpMode]);

  // console.log('isNlp', isNlp);

  useEffect(() => {
    // Se isNlp è stato disattivato da true a false e c'è input, resettiamo tutto
    if (!isNlp && input && newsFilter.isNlpMode === false) {
      setInput('');
      setSave('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNlp, newsFilter.isNlpMode]);

  useEffect(() => {
    if (newsFilter.fullSearchText) {
      setInput(newsFilter.fullSearchText);
      setSave(newsFilter.fullSearchText);
    } else {
      setInput('');
      setSave('');
    }
  }, [newsFilter.fullSearchText]);

  const customStyles: StylesConfig<any, false> = {
    control: (styles) => ({
      ...styles,
      border: `1px solid ${
        newsFilter.fullSearchTextError ? 'red !important' : '#ccc'
      }`,
      boxShadow: isMobile ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '',
      borderRadius: isMobile ? 10 : 4,
      '&:active': {
        border: `1px solid ${
          newsFilter.fullSearchTextError ? 'red !important' : '#3B82F6'
        }`,
        //boxShadow: '0 0 0 1px #2684ff',
      },
      '&:hover': {
        border: `1px solid ${
          newsFilter.fullSearchTextError ? 'red !important' : '#AAA8A8'
        }`,
        //boxShadow: '0 0 0 1px #2684ff',
      },
      '&:focus': {
        border: `1px solid ${
          newsFilter.fullSearchTextError ? 'red !important' : '#AAA8A8'
        }`,
        //boxShadow: '0 0 0 1px #2684ff',
      },
      '&:focus-within': {
        border: `1px solid ${
          newsFilter.fullSearchTextError ? 'red !important' : '#AAA8A8'
        }`,
        boxShadow: `0 0 0 1px ${
          newsFilter.fullSearchTextError ? 'red !important' : '#2684ff'
        }`,
      },
      minWidth: '170px',
    }),
    indicatorsContainer: () => ({ display: 'none' }),
    menu: () => ({ display: 'none' }),
    placeholder: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#ccc' : '#454343',
    }),
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFunction = useCallback(
    lodash.debounce((newValue: string) => {
      try {
        if (!isNlp) mapBoonExprToType(newValue, handleTokenAsPredicate);
        dispatch(
          setWorkingAreaFilter({
            ...newsFilter,
            fullSearchText: newValue,
          })
        );
      } catch {
        if (!isNlp) dispatch(setShowNewsSearchModal(true));
      }
    }, 500),
    [isNlp]
  );

  const setFilter = (newValue: string) => {
    dispatch(setFullSearchTextFilter(newValue));
    if (isMobile) {
      debouncedFunction(newValue);
    }
  };

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInput(newValue);
      setFilter(newValue);
    }
  };

  return (
    <Select
      placeholder={inputSave || 'Cerca una notizia'} // when blurred & value == "" this shows
      value="" // always show placeholder
      inputValue={input} // allows you continue where you left off
      onInputChange={onInputChange} // allows for actually typing
      onMenuClose={() => setSave(input)} // before the input is cleared, save it
      onFocus={() => {
        setInput(inputSave);
        setSave(''); // prevents undesired placeholder value
      }}
      blurInputOnSelect // actually allows for ^^ to work
      components={{ Control }}
      styles={customStyles}
      isDisabled={isDisabled}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (newsFilter.fullSearchTextError) {
            dispatch(setShowNewsSearchModal(true));
          } else {
            dispatch(setWorkingAreaFilter(newsFilter));
          }
        }
      }}
    />
  );
};

const Control = ({ children, ...props }: any) => (
  <components.Control {...props}>
    <SvgIcon
      svg={
        <Search
          style={{ fill: props.isDisabled ? '#ccc' : '#454343' }}
          className="-translate-y-0.5"
        />
      }
      className="h-5 w-5 ml-2"
    />
    {children}
  </components.Control>
);
