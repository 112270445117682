import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../Text';
import { FilterBoxChildrenProps } from '../FilterBox';
import { setNlpModeFilter } from '../../redux/newsFilter/actions';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';

const NlpModeFilter = ({ isDisabled }: FilterBoxChildrenProps) => {
  const dispatch = useDispatch();
  const newsFilter = useSelector(newsFilterSelector);
  const [showNotice, setShowNotice] = useState(false);

  // Se viene impostato un dateRange, forza isNlpMode a false
  if (newsFilter.dateRange && newsFilter.isNlpMode) {
    dispatch(setNlpModeFilter(false));
  }

  const handleNlpModeChange = () => {
    const newValue = !newsFilter.isNlpMode;
    dispatch(setNlpModeFilter(newValue));

    if (newValue) {
      setShowNotice(true);
      setTimeout(() => setShowNotice(false), 3000);
    }
  };

  return (
    <div className="relative">
      <div className="flex items-center space-x-2">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="nlp-mode"
            checked={newsFilter.isNlpMode}
            onChange={handleNlpModeChange}
            disabled={isDisabled || !!newsFilter.dateRange}
            className="form-checkbox h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center">
          <Text
            text="NLP"
            className={`text-sm cursor-pointer select-none ${
              newsFilter.dateRange ? 'text-gray-400' : ''
            }`}
          />
        </div>
      </div>

      {/* Notice slider con posizionamento fixed */}
      <div
        className={`fixed top-28 left-4 transform transition-all duration-300 ease-in-out z-50 ${
          showNotice
            ? 'translate-x-0 opacity-100'
            : '-translate-x-full opacity-0'
        }`}
      >
        <div className="bg-white border border-gray-200 shadow-lg rounded px-3 py-2">
          <Text
            text="La ricerca NLP è disponibile solo per le notizie di oggi"
            className="text-sm text-gray-600 whitespace-nowrap"
          />
        </div>
      </div>
    </div>
  );
};

export default NlpModeFilter;
