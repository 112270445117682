import { FC, useEffect, useState } from 'react';
import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import { useIdleTimer } from 'react-idle-timer';
import { useMutation } from '@apollo/client';
import { PING } from './graphql/news/mutation';
import { setIsMobile } from './redux/utils/actions';
import { useDispatch } from 'react-redux';
import { DesktopOrMobile } from './DesktopOrMobile';

export const App: FC = () => {
  const dispatch = useDispatch();
  dispatch(setIsMobile());
  const pingInterval = 1000 * 60;
  const [pingMutation] = useMutation(PING, {
    fetchPolicy: 'network-only',
  });

  // Ping iniziale
  useEffect(() => {
    // Esegue il ping immediatamente all'avvio
    pingMutation();
    // Avvia il timer per i ping successivi
    const initialTimer = setInterval(() => pingMutation(), pingInterval);
    setTimerId(initialTimer);

    // Cleanup quando il componente viene smontato
    return () => {
      clearInterval(initialTimer);
    };
  }, []); // Array vuoto -> verrà eseguito solo al mount del componente

  const pingFunction = () => {
    return setInterval(() => pingMutation(), pingInterval);
  };
  const [timerId, setTimerId] = useState<NodeJS.Timer | undefined>(undefined);
  const handleOnIdle = () => {
    clearInterval(timerId);
    setTimerId(undefined);
  };

  const handleOnActive = (event?: Event | undefined) => {
    if (!timerId) {
      setTimerId(pingFunction());
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnActive,
    debounce: 500,
  });
  return <DesktopOrMobile />;
};
